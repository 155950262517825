.controlPanel {
  align-self: flex-end;
  z-index: 1;
  margin-top: 10%;
  position: relative;
  align-items: flex-start;
}

.controlPanel .date {
  text-transform: uppercase;
  margin-bottom: 8px;
}

.controlPanel .time {
  text-transform: uppercase;
  border-radius: 6px;
  border: 2px solid var(--color-default);
  padding: 6px 24px;
  margin-bottom: 16px;
}
.controlPanel .themeLabel {
  margin-top: 18px;
}

.controlPanel .btn {
  cursor: pointer;
}
.controlPanel .btn * {
  transition: all ease 200ms;
}
.controlPanel .btn:hover img {
  transform: scale(1.05);
}
.controlPanel .btn:hover p {
  transform: translateX(5px);
}

.spotifyEmbed {
  border-radius: 12px;
  margin-top: 12px;
}

.spotifyWrapper {
  width: 320px;
}
@media only screen and (max-width: 1200px) {
  .controlPanel {
    flex-direction: row !important;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    align-self: center;
    margin: 0px;
    margin-top: 56px;
    padding: 0px;
    justify-content: center;
  }
  .controlPanel p {
    display: none;
  }
  .controlPanel img {
    width: 64px;
  }
  .spotifyWrapper {
    width: 100%;
    margin-bottom: 80px;
  }
  .controlPanel .themeSelector {
    display: none;
  }
}
