.videoContainer {
  pointer-events: none;
  position: absolute;
  height: 100%;
  top: 0px;
  z-index: 0;
  max-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px -25px !important;
}

.videoPlayer {
  width: 100%;
  height: 80%;
  min-height: 70vh;
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  .videoContainer {
    margin-top: 32px;
    position: relative;
    height: auto;
    min-height: 60vh;
    overflow: hidden;
  }
  .videoPlayer {
    margin-left: 32px;

    width: 200vw;
    object-fit: contain;
  }
}
