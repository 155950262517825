.actualContainer {
  height: 75%;
}
.actionsColum {
  max-width: 400px;
  height: 100%;
  padding-right: 20px;
}

.actionsColum::-webkit-scrollbar {
  display: initial !important;
  width: 4px;
  background-color: var(--color-bg-400);
  opacity: 0.5;
}

.actionsColum::-webkit-scrollbar-thumb {
  background-color: var(--color-default);
  opacity: 0.5;
  border-radius: 10px;
}
.moods {
  max-height: 220px;
}
.taskItem:hover img {
  opacity: 1;
  transform: translateX(6px);
}
.taskItem img {
  box-sizing: border-box;
  height: 12px;
  width: 12px;

  transition: all ease 200ms;
  opacity: 0;
}

.quoteMood {
  font-family: "Butler";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  padding-left: 12px;
  border-left: 2px solid #767771;
  opacity: 0.6;
}

.noteWrite {
  color: #282820;
}

@media only screen and (max-width: 1200px) {
  .actualContainer {
    overflow: scroll;
    height: 90vh;
    width: unset;
    padding-top: 80px !important;
    padding-bottom: 80px;
    align-items: center;
    display: block !important;
    justify-content: flex-start;
  }
  .containerParent {
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    width: 98%;
  }
  .actualContainer::-webkit-scrollbar {
    display: none;
  }
  .actionsColum {
    width: 100%;
    height: unset !important;
    overflow: unset !important;
  }
}
