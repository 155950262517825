@font-face {
  font-family: "SF Compact";
  src: url("../assets/fonts/FontRegular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "SF Compact";
  src: url("../assets/fonts/FontMedium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "SF Compact";
  src: url("../assets/fonts/FontSemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "SF Compact";
  src: url("../assets/fonts/FontBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Butler";
  src: url("../assets/fonts/Butler_Regular.otf");
  font-weight: 400;
}

:root {
  --size-xs: 4px;
  --size-s: 8px;
  --size-m: 16px;
  --size-l: 32px;
  --size-xl: 64px;

  --d-shadow: 0px 3px 20px #00000020;
  --d-transform: all ease-in-out 200ms;
  --d-transform-2: all ease-in-out 400ms;
}

body {
  font-family: "SF Compact", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100vh;
  user-select: none;
  background-color: var(--color-bg-100);
  overflow: hidden;
}
body * {
  box-sizing: border-box;
  font-family: "SF Compact", sans-serif;
}

.m-xs {
  margin: var(--size-xs);
}

.m-s {
  margin: var(--size-s);
}
.m-m {
  margin: var(--size-m);
}
.m-l {
  margin: var(--size-l);
}
.m-xl {
  margin: var(--size-xl);
}

.mh-xs {
  margin-left: var(--size-xs);
  margin-right: var(--size-xs);
}

.mh-s {
  margin-left: var(--size-s);
  margin-right: var(--size-s);
}
.mh-m {
  margin-left: var(--size-m);
  margin-right: var(--size-m);
}
.mh-l {
  margin-left: var(--size-l);
  margin-right: var(--size-l);
}
.mh-xl {
  margin-left: var(--size-xl);
  margin-right: var(--size-xl);
}

.mv-xs {
  padding-top: var(--size-xs);
  margin-bottom: var(--size-xs);
}

.mv-s {
  margin-top: var(--size-s);
  margin-bottom: var(--size-s);
}
.mv-m {
  margin-top: var(--size-m);
  margin-bottom: var(--size-m);
}
.mv-l {
  margin-top: var(--size-l);
  margin-bottom: var(--size-l);
}
.mv-xl {
  margin-top: var(--size-xl);
  margin-bottom: var(--size-xl);
}

.p-xs {
  padding: var(--size-xs);
}

.p-s {
  padding: var(--size-s);
}
.p-m {
  padding: var(--size-m);
}
.p-l {
  padding: var(--size-l);
}
.p-xl {
  padding: var(--size-xl);
}

.ph-xs {
  padding-left: var(--size-xs);
  padding-right: var(--size-xs);
}

.ph-s {
  padding-left: var(--size-s);
  padding-right: var(--size-s);
}
.ph-m {
  padding-left: var(--size-m);
  padding-right: var(--size-m);
}
.ph-l {
  padding-left: var(--size-l);
  padding-right: var(--size-l);
}
.ph-xl {
  padding-left: var(--size-xl);
  padding-right: var(--size-xl);
}

.pv-xs {
  padding-top: var(--size-xs);
  padding-bottom: var(--size-xs);
}

.pv-s {
  padding-top: var(--size-s);
  padding-bottom: var(--size-s);
}
.pv-m {
  padding-top: var(--size-m);
  padding-bottom: var(--size-m);
}
.pv-l {
  padding-top: var(--size-l);
  padding-bottom: var(--size-l);
}
.pv-xl {
  padding-top: var(--size-xl);
  padding-bottom: var(--size-xl);
}

.fw {
  width: 100vw;
}
.fh {
  height: 100vh;
}

.hvh {
  height: 50vh;
}

.fillw {
  width: 100%;
}
.fillh {
  height: 100%;
}
.ft {
  opacity: 1;
}
.st {
  opacity: 0.5;
}
.qt {
  opacity: 0.25;
}

.scrollable {
  overflow-y: scroll;
}
.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable.x {
  overflow-x: scroll;
  overflow-y: auto;
}

.snap-scroll {
  scroll-snap-type: y mandatory;
}
.snappable-center {
  scroll-snap-align: center;
}
.snappable {
  scroll-snap-align: start;
}

.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

div.col {
  display: flex;
  flex-direction: column;
}
div.row {
  display: flex;
  flex-direction: row;
}
div.wrap {
  flex-wrap: wrap;
}

div.col.ch {
  align-items: center;
}
div.col.cv {
  justify-content: center;
}
div.col.ev {
  justify-content: flex-end;
}
div.col.eh {
  align-items: flex-end;
}

div.col.sv {
  justify-content: flex-start;
}
div.col.sh {
  align-items: flex-start;
}

div.row.cv {
  align-items: center;
}
div.row.ch {
  justify-content: center;
}
div.row.ev {
  align-items: flex-end;
}
div.row.eh {
  justify-content: end;
}

div.row.sh {
  justify-content: flex-start;
}
div.row.sv {
  align-items: flex-start;
}

div.g-s {
  gap: var(--size-s);
}
div.g-m {
  gap: var(--size-m);
}
div.g-l {
  gap: var(--size-l);
}
div.g-xl {
  gap: var(--size-xl);
}

.trans {
  transition: var(--d-transform);
}
.trans-2 {
  transition: var(--d-transform-2);
}

.b-accent {
  background-color: var(--color-primary);
}
.point {
  cursor: pointer;
}
