h1,
h2 {
  color: var(--color-default);
}

h3 {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  color: var(--color-default);
}

h4 {
  line-height: 28px;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: var(--color-default);
}

h6 {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: var(--color-default);
}

p {
  font-size: 14px;
  margin: 0px;
  font-weight: 600;
  line-height: 17px;
  color: var(--color-default);
}

p.xl {
  font-size: 18px;
  line-height: 22px;
}

p.xs {
  font-size: 12px;
}
p.m {
  font-size: 16px;
}
p.jfy {
  text-align: justify;
}
.center {
  text-align: center;
}

p.xxs {
  font-size: 9px;
}

p.quote {
  font-family: "Butler";
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}
.accent {
  color: var(--color-primary);
}

.cap {
  text-transform: capitalize;
}

a {
  text-decoration: underline;
}

a:visited {
  color: var(--color-default);
}

.right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
