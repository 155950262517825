.container {
  margin-right: auto;
  margin-left: auto;
  width: calc(100vw - 50px);
}

@media (min-width: 1350px) {
  .container {
    width: 1300px !important;
  }
}

@media (min-width: 1550px) {
  .container {
    width: 1500px !important;
  }
}

@media (min-width: 1800px) {
  .container {
    width: 1700px !important;
  }
}

button {
  border: 0px;
  background-color: var(--color-btn-bg);
  color: var(--color-btn-color);
  font-weight: 700;
  border-radius: 12px;
  padding: 12px 32px;
  font-size: 18px;
  cursor: pointer;

  transition: all ease 200ms;
}

button.secondary {
  border: 2px solid var(--color-btn-color-secondary);
  background-color: transparent;
  color: var(--color-default);
  font-weight: 700;
  border-radius: 12px;
  padding: 12px 32px;
  font-size: 18px;
  cursor: pointer;
  transition: all ease 200ms;
}
button:disabled {
  opacity: 0.5;
}
button.s {
  height: 30px;
  padding: 0px 24px;
  font-size: 13px;
  font-weight: 600;
}

button:hover {
  opacity: 0.5;
}

img.icon {
  aspect-ratio: 1;
  height: 32px;
}

img.icon.xs {
  height: 16px;
}

img.icon.s {
  height: 24px;
}

img.icon.m {
  height: 48px;
}

img.icon.l {
  height: 64px;
}

img.icon.xl {
  height: 102px;
}

input {
  border: 1px solid var(--color-default);
  outline: none;
  box-sizing: border-box;
  border-radius: 12px;
  color: var(--color-default);
  font-weight: 500;
  padding: 12px 16px !important;
  background-color: var(--color-bg-000);
  font-size: 18px;
  line-height: 21px;
}

.hoverable {
  transition: all ease 200ms;
  cursor: pointer;
}
.hoverable:hover {
  transform: scale(1.03);
}

.card {
  border-radius: 8px;
  background-color: var(--color-bg-000);
  padding: 16px 24px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px var(--color-shadow);
}

.sw {
  width: 500px;
  max-width: 95vw;
  max-height: 800px;
}

textarea {
  border: 2px solid var(--color-bg-200);
  outline: none;
  box-sizing: border-box;
  border-radius: 6px;
  color: var(--color-default);
  font-weight: 500;
  padding: 16px 18px !important;
  background-color: var(--color-bg-000);
  font-size: 18px;
  line-height: 22px;
  resize: none;
}

.ant-modal-content {
  border-radius: 12px;
  background-color: var(--color-bg-000);
  box-sizing: border-box;
  box-shadow: 0px 0px 20px var(--color-shadow);
}

.ant-modal-content button {
  border-radius: 8px;
  padding: 0px 32px;
  font-size: 15px;
  cursor: pointer;
  transition: all ease 200ms;
}

.ant-popover-inner {
  border-radius: 12px;
  background-color: var(--color-bg-200);
  box-sizing: border-box;
  box-shadow: 0px 0px 20px var(--color-shadow);
}

.ant-checkbox-inner {
  background-color: var(--color-bg-100);
  border: 1px solid var(--color-bg-300);
}
.skeleton {
  background: linear-gradient(
    70deg,
    var(--color-bg-100),
    var(--color-bg-200),
    var(--color-bg-100)
  );
  background-size: 200%;
  animation: loader 2s infinite reverse;
}

.skeleton.card.xl {
  height: 700px;
}

.skeleton.card.l {
  height: 400px;
}

.skeleton.card.m {
  height: 80px;
}

.skeleton.avatar {
  height: 42px;
  width: 42px;
}

.skeleton.price {
  height: 38px;
  width: 85px;
  border-radius: 12px;
}

.skeleton.line {
  height: 32px;
  width: 240px;
  border-radius: 12px;
}

/* skeleton animation */
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

.overlay {
  backdrop-filter: blur(60px);
  position: absolute;
  z-index: 10;
  background-color: var(--color-bg-100-t);
}
