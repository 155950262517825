.moodItem .value {
  height: 100%;
  width: 100%;
  height: 56px;
  width: 56px;
  border-radius: 32px;
  background-color: var(--color-mood-bg);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.moodItem .value > div {
  height: 100%;
  width: 100%;
  background-color: var(--color-mood-color);
}

.moodItem p {
  font-weight: 500;
}
