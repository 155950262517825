.moodSelector > div {
  height: 64px;
  width: 64px;
  border-radius: 32px;
  transition: all ease 400ms;
  cursor: pointer;
  padding: 4px;
  margin: 0px 12px;
}
.moodSelector > div.hide {
  height: 0px;
  width: 0px;
  padding: 0px;
  margin: 0px;
  opacity: 0;
}
.moodSelector > div > div {
  height: 100%;
  width: 100%;
  background-color: var(--color-mood-bg);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.moodSelector > div:hover {
  outline: var(--color-default) solid 4px;
}

.moodSelector > div > div > div {
  height: 100%;
  width: 100%;
  background-color: var(--color-mood-color);
}
@media only screen and (max-width: 1200px) {
  .moodSelector > div {
    height: 48px;
    width: 48px;
    margin: 0px 8px;
  }
}
