.header {
  z-index: 999999;
}
.header .settingsMobile {
  display: none;
}
.logo {
  height: 40px;
}
.header .popup {
  position: absolute;
  background-color: var(--color-default);
  border-radius: 8px;
  right: 0;
  width: 160px;
  margin-top: 20px;
  overflow: visible;
}

.arrow {
  position: absolute;
  left: calc(50% - 12px);
  top: -12px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--color-default);
}

.header .popup a {
  color: var(--color-bg-100);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}
.buttonContainer {
  width: 160px;
  padding-left: 70px;
}
@media only screen and (max-width: 1100px) {
  .header .settingsMobile {
    display: inline;
  }
  .header {
    padding: 18px;
  }
  .buttonContainer {
    width: 160px;
    padding-left: 120px;
  }
  .arrow {
    position: absolute;
    left: unset;
    right: 16px;
  }
}
