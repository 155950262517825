.loginContainer {
  backdrop-filter: blur(60px);
  position: absolute;
  z-index: 10;
  background-color: var(--color-bg-100-t);
}

.loginContainer form {
  width: calc(min(400px, 100vw - 48px));
}

.loginContainer .close {
  align-self: flex-end;
}

.loginContainer .passwordIcon {
  right: 16px;
}

.min0 {
  min-width: 0;
}
