.breatheAnimation * {
  transition: all ease-in-out;
}

.breatheAnimation .in path,
.breatheAnimation .hold path {
  d: path(
    "M456,331.5Q413,413,331.5,451Q250,489,165,454.5Q80,420,46,335Q12,250,47,166Q82,82,166,44Q250,6,330.5,47.5Q411,89,455,169.5Q499,250,456,331.5Z"
  );
}
.breatheAnimation .out path {
  d: path(
    "M434.5,312Q374,374,312,414.5Q250,455,174.5,428Q99,401,73,325.5Q47,250,78,179.5Q109,109,179.5,67Q250,25,308,79.5Q366,134,430.5,192Q495,250,434.5,312Z"
  );
}

.breatheAnimation .idle {
  transform: scale(0.9);
}

.breatheAnimation .in,
.breatheAnimation .hold {
  transform: scale(1.15);
}
.breatheAnimation .out {
  transform: scale(0.9);
}
.breatheAnimation {
  height: 400px;
  width: 400px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 90vw;
  max-height: 90vw;
  position: relative;
  border: 2px solid var(--color-bg-200);
}

.breatheAnimation > div {
  height: 65%;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--color-breath-center);
  box-shadow: 0px 0px 30px 10px var(--color-default);
  z-index: 2;
  border-radius: 50%;
}
