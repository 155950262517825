.react-calendar {
  width: 440px;
}

.react-calendar__tile {
  width: 60px;
  height: 60px !important;
  background-color: var(--color-calendar-tile);
  border-radius: 4px;
  color: var(--color-default);
  font-size: 14px;
  padding: 6px;
  display: inline-flex; /* keep the inline nature of buttons */
  align-items: flex-start; /* this is default */
  position: relative;
}
.react-calendar__month-view__days {
  display: grid !important;
  gap: 8px;
  grid-template-columns: 14.1% 14.1% 14.1% 14.1% 14.1% 14.1% 14.1%;
}
.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
  pointer-events: none;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
}
.react-calendar__month-view__weekdays {
  margin-top: 16px;
  gap: 8px;
}

.react-calendar__navigation__prev2-button {
  color: var(--color-default);
  padding: 0px 12px;
  background: none;
}
.react-calendar__navigation__prev-button {
  color: var(--color-default);
  padding: 0px 12px;
  background: none;
}
.react-calendar__navigation__next-button {
  color: var(--color-default);
  padding: 0px 12px;
  background: none;
}

.react-calendar__navigation__next2-button {
  color: var(--color-default);
  padding: 0px 12px;
  background: none;
}
.react-calendar__month-view__weekdays__weekday {
  color: var(--color-default);
}
.react-calendar__navigation__label {
  flex: 1;
  color: var(--color-default);
  background: none !important;
  padding: 8px 12px;
  opacity: 1 !important;
  border: 2px var(--color-default) solid;
}
.react-calendar__navigation {
  display: flex;
  gap: 12px;
}

.doneDay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--color-calendar-tile-active);
  top: 0px;
  left: 0px;
  display: inline-flex; /* keep the inline nature of buttons */
  align-items: flex-start; /* this is default */
  padding: 6px;
}
.doneDay img {
  position: absolute;
  width: 40%;
  height: 40%;
  object-fit: contain;
  left: 30%;
  top: 30%;
}
.doneDay p {
  color: white;
}
.insightsBtn {
  background-color: var(--color-btn-bg);
  margin-top: 24px;
  border-radius: 8px;
  width: 120px;
}
.insightsBtn p {
  color: var(--color-btn-color);
}
@media only screen and (max-width: 1200px) {
  .calendarPage {
    flex: none !important;
  }

  .react-calendar {
    width: 90%;
  }
  .react-calendar__tile {
    width: 48px;
    height: 48px !important;
  }
  .react-calendar__month-view__days {
    gap: 5px;
  }
  .react-calendar__month-view__weekdays {
    gap: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .react-calendar__tile {
    width: 40px;
    height: 40px !important;
  }
}

@media only screen and (max-width: 340px) {
  .react-calendar__tile {
    width: 35px;
    height: 35px !important;
  }
}
