@keyframes fade-in {
  0% {
    opacity: 0;
  }
  98% {
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes scale-right-x {
  0% {
    transform-origin: 0% 50%;
    transform: scaleX(0) scaleY(0);
  }
  10% {
    transform-origin: 0% 50%;
    transform: scaleX(0.05) scaleY(1);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scaleX(1) scaleY(1);
  }
}
@keyframes scale-left-x {
  0% {
    transform-origin: 100% 50%;
    transform: scaleX(0) scaleY(0);
  }
  10% {
    transform-origin: 100% 50%;
    transform: scaleX(0.05) scaleY(1);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes scale-center {
  0% {
    transform-origin: 50% 50%;
    opacity: 0;
    transform: scaleX(0.9) scaleY(0.9);
  }
  100% {
    transform-origin: 50% 50%;
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
}
@keyframes scale-top-y {
  0% {
    transform-origin: 50% 100%;
    transform: scaleX(0) scaleY(0);
  }
  10% {
    transform-origin: 50% 100%;
    transform: scaleY(0.05) scaleX(1);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes scale-bottom-y {
  0% {
    transform-origin: 50% 0%;
    transform: scaleX(0) scaleY(0);
  }
  10% {
    transform-origin: 50% 0%;
    transform: scaleY(0.05) scaleX(1);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes float-1 {
  0% {
    transform-origin: 0% 0%;
    transform: translateY(0px);
  }
  50% {
    transform-origin: 20% 0%;
    transform: translateY(-40px) rotate(30deg);
  }
  100% {
    transform-origin: 0% 0%;
    transform: translateY(0px);
  }
}
@keyframes float-2 {
  0% {
    transform-origin: 0% 0%;
    transform: translateX(0px);
  }
  50% {
    transform-origin: 0% 20%;
    transform: translateX(-40px) rotate(30deg);
  }
  100% {
    transform-origin: 0% 0%;
    transform: translateX(0px);
  }
}

.float-1 {
  animation: float-1 30s ease-in-out infinite;
}
.float-2 {
  animation: float-2 40s ease-in-out infinite;
}

.fade-in {
  animation: fade-in 0.4s linear;
  animation-fill-mode: both;
}

.slide-right {
  animation: slide-right 0.4s linear;
  animation-fill-mode: both;
}
.slide-bottom {
  animation: slide-bottom 0.4s linear;
  animation-fill-mode: both;
}
.slide-left {
  animation: slide-left 0.4s linear;
  animation-fill-mode: both;
}
.scale-right-x {
  animation: scale-right-x 0.4s linear;
  animation-fill-mode: both;
}
.scale-center {
  animation: scale-center 0.4s linear;
  animation-fill-mode: both;
}
.scale-left-x {
  animation: scale-left-x 0.4s linear;
  animation-fill-mode: both;
}
.scale-top-y {
  animation: scale-top-y 0.4s linear;
  animation-fill-mode: both;
}

.scale-bottom-y {
  animation: scale-bottom-y 0.4s linear;
  animation-fill-mode: both;
}

.a1 {
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
}
.a2 {
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
}
.a3 {
  -webkit-animation-duration: 450ms;
  animation-duration: 450ms;
}
.a4 {
  -webkit-animation-duration: 600ms;
  animation-duration: 600ms;
}
.a5 {
  -webkit-animation-duration: 750ms;
  animation-duration: 750ms;
}
.a6 {
  -webkit-animation-duration: 900ms;
  animation-duration: 900ms;
}
.a-1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.a-2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.a-3 {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

.d1 {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}
.d2 {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}
.d3 {
  -webkit-animation-delay: 450ms;
  animation-delay: 450ms;
}
.d4 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}
.d5 {
  -webkit-animation-delay: 750ms;
  animation-delay: 750ms;
}
.d6 {
  -webkit-animation-delay: 900ms;
  animation-delay: 900ms;
}
.d-1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.d-2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.d-3 {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.ease {
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}
.ease-in {
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.ease-in-quad {
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.ease-in-cubic {
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.ease-in-quart {
  -webkit-animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.ease-in-quint {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ease-in-expo {
  -webkit-animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
.ease-in-circ {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.ease-out {
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.ease-out-quad {
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ease-out-cubic {
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ease-out-quart {
  -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.ease-out-quint {
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ease-out-expo {
  -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.ease-out-circ {
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.ease-in-out {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.ease-in-out-quad {
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.ease-in-out-cubic {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ease-in-out-quart {
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.ease-in-out-quint {
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.ease-in-out-expo {
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
}
.ease-in-out-circ {
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.pause {
  animation-play-state: paused;
}

.play {
  animation-play-state: running;
}
