.dropdown {
  padding: 8px 8px 8px 16px;
  border: 2px solid var(--color-btn-color-secondary);
  height: 40px;
  border-radius: 6px;
  position: relative;
  min-width: 150px;
}

.dropdown > :first-child {
  cursor: pointer;
}

.dropdown img {
  transition: all ease 200ms;
}

.dropdown p {
  font-size: 18px;
}

.dropdown .options {
  position: absolute;
  top: 45px;
  min-width: 140px;
  padding: 8px 8px 8px 16px;
  border: 2px solid #282820;
  border-radius: 6px;
  left: 0px;
  background-color: var(--color-bg-300);
  min-width: 100%;
  z-index: 100;
  max-height: 200px;
  overflow-y: scroll;
}

.dropdown .options p {
  cursor: pointer;
  transition: all ease 200ms;
  padding: 5px 0px;
}

.dropdown .options p:hover {
  opacity: 0.5;
}
