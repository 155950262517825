.breathePage .close {
  opacity: 0.3;
  position: absolute;
  bottom: 24px;
}

.colorPreview {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  right: 44px;
  border-radius: 9px;
}
