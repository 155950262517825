.themeSelector {
  background-color: var(--color-theme-selector-bg);
  border-radius: 7px;
  position: relative;
  align-items: stretch;
}

.themeSelector > p {
  padding: 12px 24px;
  z-index: 1;
  cursor: pointer;
  color: var(--color-theme-selector-color-2);
}

.themeSelector > div {
  z-index: 0;
  margin: 4px;
  border-radius: 5px;
  position: absolute;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  left: 0px;
  background-color: var(--color-theme-selector-select);
}

.themeSelector > p.optionSelected {
  color: var(--color-theme-selector-color);
}
