.sharePopupBack {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #000a;
}
.sharePopup {
  background-color: var(--color-bg-100);
  border-radius: 12px;
}

.sharePopup p {
  max-width: calc(min(70vw, 500px));
}
