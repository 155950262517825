.dailyQuote .close {
  align-self: flex-end;
}

.dailyQuote .actions {
  justify-content: space-around;
  width: 70%;
}

.dailyQuote .actions div {
  padding: 12px;
  border-radius: 32px;
  background-color: var(--color-circle-btn-bg);
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 200ms;
}

.dailyQuote .actions div:hover {
  box-shadow: 0px 0px 0px 2px var(--color-default);
}

.dailyQuote .quote {
  transition: all ease 200ms;
}
.dailyQuote .active {
  border-left: 2px solid var(--color-default);
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  padding: 4px 24px;
}

.dailyQuote .titleContainer {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.dailyQuote .titleContainer h3 {
  position: absolute;
  transition: all ease 200ms;
}

.dailyQuote .activeTitle {
  opacity: 1;
  transition-delay: 200ms !important;
}

.dailyQuote .hideTitle {
  opacity: 0;
}

.dailyQuote .noteWrite {
  min-height: 150px;
  color: #312822;
}
