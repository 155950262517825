.snackbar {
  transition: all ease 300ms;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 32px 10px;
  background-color: var(--color-default);
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  bottom: 20px;
}

.snackbar p {
  color: var(--color-bg-100);
  font-size: 18px;
  line-height: 20px;
}
