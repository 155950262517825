.onboarding {
  backdrop-filter: blur(60px);
  position: absolute;
  z-index: 10;
  background-color: var(--color-bg-100-t);
}
.introImage {
  height: 370px;
  width: 370px !important;
  border-radius: 16px;
  overflow: hidden;
  object-fit: contain;
  margin-bottom: 32px;
}
.carousel video {
  height: 340px;
  width: 330px;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
}

.onboardingContainer {
  backdrop-filter: blur(60px);
  z-index: 10;
  background-color: var(--color-bg-100-t);
}
