.addTaskBack {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #000a;
}
.addTask {
  background-color: var(--color-bg-100);
  border-radius: 12px;
}

.addTask p {
  transition: all ease 200ms;
}

.addTask p:hover {
  transform: translateX(3px);
}

.gridList {
  flex-wrap: wrap;
  max-height: 300px;
}
.elementList {
  width: 140px;
}
